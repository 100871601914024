import React from 'react';

import IconBase, { IconProps } from '../IconBase';
const Svg14X14ExternalLinkOutline = (props: Omit<IconProps, 'children'>) => {
  return (
    <IconBase viewBox="0 0 14 14" {...props} width="14" height="14">
      <>
        <path fill="currentColor" d="M6 2H4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V8h-2v2H4V4h2z" />
        <path
          fill="currentColor"
          d="M9.207 1H12.5a.5.5 0 0 1 .5.5v3.293a.5.5 0 0 1-.854.353l-.964-.964-2.357 2.357A1 1 0 0 1 7.41 5.125l2.357-2.357-.914-.914A.5.5 0 0 1 9.207 1"
        />
      </>
    </IconBase>
  );
};
export default Svg14X14ExternalLinkOutline;
