import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { render } from 'react-dom';

import App from './App';

const initializeLDProvider = async () => {
  if (environment.LAUNCHDARKLY_CLIENT_SIDE_ID) {
    const LDProvider = await asyncWithLDProvider({
      clientSideID: environment.LAUNCHDARKLY_CLIENT_SIDE_ID,
    });
    return LDProvider;
  } else {
    return ({ children }: any) => <>{children}</>;
  }
};

initializeLDProvider().then((LDProvider) => {
  render(
    <LDProvider>
      <App />
    </LDProvider>,
    document.getElementById('react-root'),
  );
});
