import { TextDisplay, Heading1, Flex, Link } from '@libs/ahoy/src';
import React from 'react';

import maintenance from './images/maintenance.svg';
import logo from './images/teamleader_logo_default.svg';
import styles from './index.css';
import { useFlagsWithFallback } from './useFlagsWithFallback';

export default function App() {
  const flags = useFlagsWithFallback();

  const timeAndDateString: string = flags.downtimeTimeAndDateValue;
  const timeAndDate = new Date(timeAndDateString);

  let timeAndDateFormatted = '';
  if (!isNaN(timeAndDate.getTime())) {
    timeAndDateFormatted = new Intl.DateTimeFormat('en-GB', {
      hour: 'numeric',
      minute: 'numeric',
    }).format(timeAndDate);
  }

  return (
    <Flex className={styles['container']} direction="column">
      <Flex justifyContent="center" paddingVertical={6} element="header">
        <img src={logo} alt="Teamleader logo" title="Teamleader logo" />
      </Flex>
      <Flex direction="column" justifyContent="center" alignItems="center" flex={1} element="main">
        <Heading1 marginBottom={6}>Whoops, Teamleader Focus is down for maintenance</Heading1>
        <TextDisplay marginBottom={3}>
          We apologize for the inconvenience as we are currently making improvements.
        </TextDisplay>
        <TextDisplay marginBottom={3}>
          We'll be back up and running at around <strong>{timeAndDateFormatted}</strong>.
        </TextDisplay>
        <TextDisplay>
          Subscribe to our{' '}
          <Link inherit={false} href="https://status.teamleader.eu">
            statuspage
          </Link>
          , to stay updated!
        </TextDisplay>
      </Flex>
      <Flex
        className={styles['main-image-container']}
        justifyContent="center"
        backgroundColor="neutral"
        backgroundTint="light"
        marginTop={6}
      >
        <img
          className={styles['main-image']}
          src={maintenance}
          alt="Maintenance on Teamleader Focus"
          title="Maintenance on Teamleader Focus"
        />
      </Flex>
    </Flex>
  );
}
